if(document.body.classList.contains('home')) {
    const servicesBlocks = document.querySelectorAll('.section--services');
    if(servicesBlocks && servicesBlocks[0]) {
        const servicesBlockHeight = servicesBlocks[0].getBoundingClientRect().height
        const pageTopBg = document.querySelector('.page-top__background')

        pageTopBg.style.setProperty('height', 'calc(100% + ' + servicesBlockHeight +'px)');
    }
}

/*******************
Toggle subnav background
*******************/
jQuery('.page-navigation>ul>li').hover(function() {
    jQuery('body').addClass('has-dark-overlay');
},function(){
    jQuery('body').removeClass('has-dark-overlay');
});

/*******************
Smooth scroll anchor links
*******************/
jQuery('a[href*="#"]:not([href="#"])').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        || location.hostname == this.hostname) {

        var target = jQuery(this.hash);
        target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            jQuery('html,body').animate({
                scrollTop: target.offset().top -176
            }, 500);
            return false;
        }
    }
});

/*******************
Automatic section scroll
*******************/
var a_sections = jQuery('.section--flex'), a_nav = jQuery('.scroll-to-nav');

jQuery(window).on('scroll', function () {
    var cur_pos = jQuery(this).scrollTop();

    a_sections.each(function() {
        var top = jQuery(this).offset().top - 300,
        bottom = top + jQuery(this).outerHeight(),
        id = jQuery(this).data('id');

        if(id) {
            if (cur_pos >= top && cur_pos <= bottom) {
                a_nav.find('a').removeClass('scroll-to-nav__link--active');
                a_nav.find('a[href="#'+id+'"]').addClass('scroll-to-nav__link--active');

                if (screen.width < 769) {
                    var parent = a_nav.find('a[href="#'+id+'"]').parent();
                    if(parent.length) {
                        var offsetLeft = parent.position().left;
                        a_nav.find('ul').css({'-webkit-transform':'translate(-' + offsetLeft + 'px)', 'transition': '.2s ease-in-out'});
                    }
                }
            }
        }
    });
});

/*******************
Toggle all tables
*******************/
jQuery('.toggle-all-tables').on('click', function(){
    jQuery(this).toggleClass('toggle-all-tables--active');
    jQuery(this).next('.hidden-tables').slideToggle('medium');
});

/*******************
Append header__top to page-navigation if screen size < 991
*******************/
var liveNow = jQuery('.live-now');

var headerTop = jQuery('.header__top .col-lg-12');
var headerBottom = jQuery('.header__bottom .col-lg-12');

var headerTopNavigation = jQuery('.top-navigation');
var headerBottomNavigation = jQuery('.page-navigation');
var languageToggle = jQuery('.wpml-ls-legacy-dropdown');

// Append on resize
jQuery(window).resize(function() {
    if (jQuery(window).width() < 992) {
        jQuery(headerBottomNavigation).append(liveNow);
        jQuery(headerBottomNavigation).append(headerTopNavigation);
        jQuery('.header__bottom .col-lg-12').append(languageToggle);
    } else {
        jQuery(headerTop).append(liveNow);
        jQuery(headerTop).append(headerTopNavigation);
        jQuery(headerTop).append(languageToggle);
    }
});

// Set default append if window < 992
if (jQuery(window).width() < 992) {
    jQuery(headerBottomNavigation).append(liveNow);
    jQuery(headerBottomNavigation).append(headerTopNavigation);
    jQuery('.header__bottom .col-lg-12').append(languageToggle);
}

/*******************
Open navigation
*******************/
jQuery('.mobile-toggle__open').on('click', function(){
    var mobileToggleClose = jQuery(this).parent().find('.mobile-toggle__close');

    jQuery(this).addClass('mobile-toggle__open--active');
    jQuery(mobileToggleClose).addClass('mobile-toggle__close--active');

    jQuery('.mobile-wrapper').addClass('mobile-wrapper--active');
    jQuery('.mobile-wrapper-new').addClass('mobile-wrapper--active');
    jQuery('header').addClass('header--mobile');
    jQuery('body').addClass('overflow');
});

/*******************
Close navigation
*******************/
jQuery('.mobile-toggle__close').on('click', function(){
    var mobileToggleOpen = jQuery(this).parent().find('.mobile-toggle__open');

    jQuery(this).removeClass('mobile-toggle__close--active');
    jQuery(mobileToggleOpen).removeClass('mobile-toggle__open--active');

    jQuery('.mobile-wrapper').removeClass('mobile-wrapper--active');
    jQuery('.mobile-wrapper-new').removeClass('mobile-wrapper--active');
    jQuery('header').removeClass('header--mobile');
    jQuery('body').removeClass('overflow');
});

/*******************
Open sub-navigation
*******************/
jQuery('.sub-menu-toggle').on('click', function() {
    var submenuWrapper1 = jQuery(this).next('.sub-menu-first');

    jQuery(this).toggleClass('sub-menu-toggle--active');
    jQuery(submenuWrapper1).slideToggle();
});


/*******************
To-top
*******************/
jQuery(document).scroll(function(){
    var scrollTop = jQuery('.new-to-top');

    if (jQuery(window).scrollTop() > 300) {
        jQuery(scrollTop).addClass('new-to-top--show');
    } else {
        jQuery(scrollTop).removeClass('new-to-top--show');
    }
});

jQuery(document).ready(function(){
    if (window.location.hash) {
		jQuery('html,body').animate({
			scrollTop: jQuery(window.location.hash).offset().top - 176
		}, 900, 'swing');
	}
});

/*******************
 Check if bottom page reached
 *******************/
if (jQuery(window).width() < 1560) {
    window.onscroll = function (e) {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            jQuery('.new-to-top').addClass('new-to-top--bottom');
        } else {
            jQuery('.new-to-top').removeClass('new-to-top--bottom');
        }
    };
}

/* ************************
Iframe Wrapper
************************ */
import swipebox from '../optional/jquery.swipebox'

// Converted due to issues with blog articles and swipebox being added
jQuery(document).ready(function($) {
    // Check if there are any images inside links within the .editor class
    if ($('.editor').length > 0) {
        $('.editor a img').each(function() {
            let $parentLink = $(this).closest('a');
            if (!$parentLink.hasClass('league-tile')) {
                $parentLink.addClass('swipebox');
                $parentLink.attr('rel', 'lightbox');
            }
        });

        // Initialize SwipeBox for links with the swipebox class
        if ($('a.swipebox').length) {
            $('a.swipebox').swipebox({
                autoplayVideos: true
            });
        }
    }
});

/* Share buttons */
jQuery('.share-this-button').on('click', function() {
    jQuery('.share-this').toggleClass('share-this--active');
    jQuery(this).toggleClass('share-this-button--active');
})

jQuery('.page-navigation__overlay').on('click', function() {
    jQuery('.mobile-wrapper-new').removeClass('mobile-wrapper--active');
    jQuery('.mobile-toggle__open').removeClass('mobile-toggle__open--active');
    jQuery('.mobile-toggle__close').removeClass('mobile-toggle__close--active');
});

/* ***********************
Widget iframes
************************ */
const widgetIframes = document.querySelectorAll('.widget-iframe');
if(widgetIframes) {
    widgetIframes.forEach((widgetIframe) => {
        widgetIframe.srcdoc = '<!DOCTYPE html><p style="text-align: center; font-family: Sofia Pro Soft Light, sans-serif;">Loading widget...</p>';
        widgetIframe.addEventListener('load', () => widgetIframe.removeAttribute('srcdoc'));
    });
}
